import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'

const FeaturedItem = ({ content, title, href }) => (
  <Link to={href} title={title} className="feature">
    <strong>Featured Article</strong>
    <p
      className="h5"
      style={{
        color: 'inherit',
      }}
    >
      {content}
    </p>
  </Link>
)

FeaturedItem.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  content: PropTypes.string,
}

export default FeaturedItem
