import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'

import FeaturedItem from '../components/featured-item'
import Listing from '../components/listing'
import InternalListing from '../components/internal-listing'

import TwitterFollow from '../components/twitter-follow'

import DefaultLayout from '../components/layouts'

const DocsetListing = props => {
  const { title, subtitle, items } = props
  return (
    <section title={title} className="cell listing docset">
      <h4 className="underline">{title}</h4>
      <ol className="no-bullet">
        {items.map(({ link, subitems }, idx) => (
          <li key={idx} className="listing-item">
            <a
              {...link}
              target="_blank"
              rel="noindex nofollow noreferrer noopener"
            >
              {link.title}
            </a>
            {subitems.map(({ title, href }, idx) => (
              <span key={idx}>
                {` •`}
                &nbsp;
                <a
                  title={title}
                  href={href}
                  target="_blank"
                  rel="noindex nofollow noreferrer noopener"
                >
                  {title}
                </a>
              </span>
            ))}
          </li>
        ))}
      </ol>
    </section>
  )
}

const IndexPage = props => {
  const sections = get(props, 'data.allListingsJson.sections')
  const docsets = get(props, 'data.allDocsetsJson.docsets')
  const features = get(props, 'data.allFeaturedJson.items')
  const posts = get(props.data, 'allMarkdownRemark.posts')

  const site = get(props, 'data.site')

  return (
    <DefaultLayout siteMetadata={site.siteMetadata} {...props}>
      <div className="grid-container">
        <div className="grid-x grid-padding-x">
          <div className="cell medium-7">
            <h2>Quick Links &amp; References for iOS Developers</h2>
            <p className="lead">
              A reference and quick link site to help iOS developers quickly get
              to relevant documentation, see examples of complicated command
              line utilities, and more.
            </p>
            <p className="lead">
              <Link to="/about/">
                For more information, see the About page.
              </Link>
            </p>
          </div>
          <div className="cell auto shrink">
            <h2>Need an iOS Developer?</h2>
            <p>Get in touch with our team today!</p>
            <p>
              <a href="https://www.traboulesoftware.com/contact-us/">
                Tell us about your project
              </a>
            </p>
          </div>
        </div>

        <section className="grid-x grid-padding-x align-justify">
          <h2 className="underline cell">Articles</h2>

          {posts.slice(0, 4).map(({ article }, idx) => (
            <article key={article.id} className="summarized cell medium-6">
              <Link
                to={article.frontmatter.path}
                title={article.frontmatter.linkTitle}
                className=""
              >
                <h3>{article.frontmatter.linkTitle}</h3>
              </Link>

              <p>
                Published on: {article.frontmatter.firstPublishedOn} •{' '}
                {article.timeToRead} minute read
              </p>

              <p>{article.frontmatter.summary}</p>
            </article>
          ))}
          <section className="cell h4" style={{ textAlign: 'center' }}>
            <Link to="/articles/" className="more-articles">
              More Articles
            </Link>
          </section>
        </section>

        <hr />
        <div className="grid-x grid-margin-x medium-up-2 large-up-3">
          {sections.map(({ section }, idx) => {
            const classNames =
              section.sectionStyle === 'inlined' ? 'menu' : undefined
            return (
              <Listing
                key={kebabCase(section.title)}
                classNames={classNames}
                {...section}
              />
            )
          })}
        </div>
        <hr />

        <h2>Documentation Quick Reference</h2>

        <div className="grid-x grid-padding-x medium-up-2">
          {docsets.map(({ set }, idx) => (
            <DocsetListing key={kebabCase(set.title)} {...set} />
          ))}
        </div>
      </div>
    </DefaultLayout>
  )
}

export default IndexPage

export const query = graphql`
  query MainSectionsQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }

    allMarkdownRemark(
      filter: {
        frontmatter: { isArticle: { eq: true }, isPublished: { eq: true } }
      }
      sort: { fields: [frontmatter___firstPublishedOn], order: DESC }
    ) {
      posts: edges {
        article: node {
          id
          frontmatter {
            path
            lastUpdatedDate(formatString: "MMMM DD, YYYY")
            firstPublishedOn
            isPublished
            isArticle
            title
            description
            summary
            linkTitle
            tags
          }
          timeToRead
        }
      }
    }

    allNoticesJson {
      notices: edges {
        note: node {
          id
          message
        }
      }
    }

    allListingsJson(sort: { fields: [order] }) {
      totalCount
      sections: edges {
        section: node {
          sectionStyle
          title
          items {
            link {
              title
              href
            }
            content
          }
        }
      }
    }

    allFeaturedJson {
      totalCount
      items: edges {
        feature: node {
          title
          href
          content
        }
      }
    }

    allDocsetsJson {
      totalCount
      docsets: edges {
        set: node {
          sectionStyle
          title
          items {
            link {
              title
              href
            }
            subitems {
              title
              href
            }
          }
        }
      }
    }
  }
`
