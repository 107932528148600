import React from 'react'

const Listing = props => {
  const { title, subtitle, classNames, items } = props
  const additionalClasses = classNames === undefined ? '' : classNames

  return (
    <section title={title} className="cell listing">
      <h4 className="underline">{title}</h4>
      <ol className={`no-bullet ${additionalClasses}`}>
        {items.map((item, idx) => (
          <li key={item.link.href} className="listing-item">
            <a {...item.link} rel=" nofollow noreferrer noopener">
              {item.content}
            </a>
          </li>
        ))}
      </ol>
    </section>
  )
}

export default Listing
